import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import LogoImg from "../../../assets/images/logo.png";
import MobileMenuImg from "../../../images/landings/mobile-menu.png";
import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import classNames from "classnames";
import LandingLanguages from "./LandingLanguages";
import {
  getItem,
  isAuthorized,
} from "../../../system/helpers/localstorageHelper";

const Header = () => {
  const body = useRef(document.getElementsByTagName("body")[0]);
  const [modalMenu, toggleMenu] = useState(false);

  useEffect(() => {
    const bodyElement = body.current;
    modalMenu
      ? (bodyElement.style.overflow = "hidden")
      : (bodyElement.style.overflow = "auto");
  }, [modalMenu]);

  const headerNavigation = (inModal: boolean) => (
    <div
      className={classNames("header__navigation-wrap", {
        "header__navigation-wrap--modal": inModal,
      })}
    >
      <ul className={"nav-menu"}>
        <li className="nav-menu__item">
          <NavLink
            to={APP_ROUTES.commissions.commission}
            className={"nav-menu__link"}
          >
            {_t("Commissions")}
          </NavLink>
        </li>
        <li className="nav-menu__item">
          <NavLink to={APP_ROUTES.faq.index} className={"nav-menu__link"}>
            {_t("FAQ")}
          </NavLink>
        </li>
        <li className="nav-menu__item">
          <NavLink to={APP_ROUTES.contacts} className={"nav-menu__link"}>
            {_t("Contacts")}
          </NavLink>
        </li>
        {isAuthorized() ? (
          <li className="nav-menu__item">
            {getItem("role") === "admin" ? (
              <NavLink
                to={APP_ROUTES.dashboard.admin}
                className={"nav-menu__link"}
              >
                {_t("Dashboard")}
              </NavLink>
            ) : (
              <NavLink
                to={APP_ROUTES.dashboard.general}
                className={"nav-menu__link"}
              >
                {_t("Dashboard")}
              </NavLink>
            )}
          </li>
        ) : null}
      </ul>
      <LandingLanguages />
      {!isAuthorized() && (
        <div className="header__button-wrap">
          <Link to={APP_ROUTES.login.main} className="btn btn--border">
            {_t("sign_in")}
          </Link>
          <Link to={APP_ROUTES.registration} className="btn">
            {_t("sign_up")}
          </Link>
        </div>
      )}
    </div>
  );

  return (
    <header className="header">
      <div className="container">
        <div className="header__wrap">
          <NavLink to={"/"} className={"header__logo-link"}>
            <div className="header__logo-cont">
              <img src={LogoImg} className={"header__logo-img"} />
            </div>
          </NavLink>

          {headerNavigation(false)}

          <button className="burger-menu" onClick={() => toggleMenu(true)}>
            <img src={MobileMenuImg} alt="burger" />
          </button>

          <div className={classNames("menu-modal", modalMenu && "opened")}>
            <button
              type="button"
              className="close-modal-button"
              onClick={() => toggleMenu(false)}
            />
            {headerNavigation(true)}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
