import React from "react";
import {FaqResponse} from "../../api";
import InfoCard from "../../../Landing/components/InfoCard";
import classnames from "classnames";

type FaqTitlesProps = {
  data: FaqResponse;
  loading: boolean;
  title: string;
  tileClass: string;
  selected: string;
  requestParam: string;
  setFilter: (v) => void;
  image: any;
};

export const FaqTitle = ({setFilter, requestParam, title, selected}) => {

  return <button onClick={() => setFilter(requestParam)}
                 className={`faq-card-nav ${selected}`}>
    {title}

  </button>
}
