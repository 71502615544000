import React from "react";
import moment from "moment";
import Btn from "../components/Button";
import Header from "../../Layouts/header/Header";
import CheckboxInput from "../components/CheckBoxInput";
import SearchInput from "../components/SearchInput";
import SelectInput from "../components/SelectInput";
import SimpleInput from "../components/SimpleInput";
import { ReactComponent as IconAdd } from "../../../images/icons/add.svg";
import RadioBtn from "../components/Radio";
import Switcher from "../components/Switch";
import { DatePicker } from "../components/DatePicker";
import { RadioSelect } from "../components/RadioSelect";
import { Filters } from "../components/Filters";
import { Space } from "antd";
import Form from "antd/lib/form/Form";
import { CheckBoxSelect } from "../components/CheckBoxSelect";

const componentsList = [
  {
    title: "primary", //element name
    element: <Btn type="primary" title={"primary"} />,
    col: 3,
  },
  {
    title: "secondary", //element name
    element: <Btn type="secondary" title={"secondary"} />,
    col: 3,
  },
  {
    title: "tertiary", //element name
    element: <Btn type="tertiary" title={"tertiary"} />,
    col: 3,
  },
  {
    title: "with icon", //element name
    element: <Btn type="with-icon" title={"with icon"} icon={<IconAdd />} />,
    col: 3,
  },
  {
    title: "default", //element name
    element: <Btn type="default" />,
    col: 3,
  },
  {
    title: "loading", //element name
    element: <Btn type="primary" title={""} loading />,
    col: 3,
  },
  {
    title: "loading with title", //element name
    element: <Btn type="primary" title={"test"} loading />,
    col: 3,
  },
  {
    title: "сheckbox",
    element: <CheckboxInput />,
  },
  {
    title: "radio",
    element: <RadioBtn />,
  },
  {
    title: "switcher",
    element: <Switcher />,
  },
  {
    title: "CheckBoxSelect",
    element: (
      <CheckBoxSelect
        onChange={console.log}
        options={[
          { value: "test", label: "label test" },
          { value: "test2", label: "label test2" },
          { value: "test3", label: "label test3" },
          { value: "test4", label: "label test4" },
        ]}
      >
        asdasd
      </CheckBoxSelect>
    ),
  },

  {
    title: "Header", //element name
    element: (
      <Header
        links={[
          { title: "test", path: "/" },
          { title: "test1", path: "/" },
        ]}
      />
    ),
    col: 24,
  },
  {
    title: "Simple input",
    element: <SimpleInput placeholder="Enter some value" />,
    col: 8,
  },
  {
    title: "Search input",
    element: <SearchInput placeholder="Search Name or ID" />,
    col: 8,
  },
  {
    title: "Multiple select input",
    element: (
      <SelectInput
        mode={"multiple"}
        defaultValue={["First Tag", "Second  Tag"]}
        options={[
          "First Tag",
          "Second  Tag",
          "Third Tag",
          "Fourth Tag",
          "Fifth Tag",
          "Sixth Tag",
        ].map((item) => ({
          label: item,
          value: item,
        }))}
      />
    ),
    col: 8,
  },
  {
    title: "Single select input",
    element: (
      <SelectInput
        defaultValue={"First Tag"}
        options={[
          "First Tag",
          "Second  Tag",
          "Third Tag",
          "Fourth Tag",
          "Fifth Tag",
          "Sixth Tag",
        ].map((item) => ({
          label: item,
          value: item,
        }))}
      />
    ),
    col: 8,
  },
  {
    title: "DatePicker",
    element: (
      <DatePicker
        value={{ from: moment().subtract(4, "days"), to: moment() }}
      />
    ),
    col: 8,
  },
  {
    title: "RadioSelect",
    element: (
      <RadioSelect
        onChange={console.log}
        defaultValue="test"
        options={[
          { value: "test", label: "label test" },
          { value: "test2", label: "label test2" },
          { value: "test3", label: "label test3" },
          { value: "test4", label: "label test4" },
        ]}
      />
    ),
    col: 8,
  },
  {
    title: "Filters",
    element: (
      <div style={{ textAlign: "left" }}>
        <Filters
          initialValues={{
            datepicker1: { from: moment().subtract(4, "days"), to: moment() },
            select1: "Third Tag",
            select2: "Sixth Tag",
          }}
          mainFilters={
            <Space size={22} direction="horizontal" align="center">
              <Form.Item align="center" name="datepicker1">
                <DatePicker onChange={console.log} />
              </Form.Item>

              <Form.Item align="center" name="select1">
                <SelectInput
                  values={[
                    "First Tag",
                    "Second  Tag",
                    "Third Tag",
                    "Fourth Tag",
                    "Fifth Tag",
                    "Sixth Tag",
                  ]}
                />
              </Form.Item>
              <Form.Item align="center" name="select2">
                <SelectInput
                  values={[
                    "First Tag",
                    "Second  Tag",
                    "Third Tag",
                    "Fourth Tag",
                    "Fifth Tag",
                    "Sixth Tag",
                  ]}
                />
              </Form.Item>
            </Space>
          }
          expandableFilters={
            <Space size={22} direction="horizontal" align="center">
              <Form.Item label="label1" name="datepicker2">
                <DatePicker
                  onChange={console.log}
                  value={{ from: moment().subtract(4, "days"), to: moment() }}
                />
              </Form.Item>
              <Form.Item label="label2" name="datepicker3">
                <DatePicker
                  onChange={console.log}
                  value={{ from: moment().subtract(4, "days"), to: moment() }}
                />
              </Form.Item>
            </Space>
          }
        />
      </div>
    ),
    col: 18,
  },
];

export default componentsList;
