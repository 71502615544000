import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import socialIconSkype from "../../../assets/images/outer/social_icon_skype.svg";
import socialIconTG from "../../../assets/images/outer/social_icon_tg.svg";

const items = (t) => [
  {
    icon: socialIconSkype,
    path: t("project_social_contact_skype"),
  },
  // {
  //   icon: socialIconTG,
  //   path: t("project_social_contact_tg"),
  // },
];

const SocialContacts = () => {
  const socialItems = items(_t);
  console.log(socialItems);
  return (
    <ul className="social-contacts">
      {socialItems.map((item, idx) => (
        <li className="social-contacts__item" key={idx}>
          <a
            href={item.path}
            target="_blank"
            rel="noreferrer"
            className="social-contacts__link"
          >
            <img src={item.icon} alt="" />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default SocialContacts;
